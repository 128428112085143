import styled from "styled-components";

const KitPageValues = styled.div`
  /* padding: 250px 0 80px; */
  overflow: hidden;
  position: relative;

  .order-kit {
    .order-wrapper {
      padding: 170px 0 80px;

      @media only screen and (max-width: 1280px) {
        padding: 150px 0 70px;
      }

      @media only screen and (max-width: 767px) {
        padding: 92px 0 50px;
      }
    }
  }


  .order-wrapper {
    padding: 250px 0 80px;
    &.checkout {
    padding-top: 0!important;
  }
}
  header.checkout-header-wrap {
    padding: 50px;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(204, 204, 204);
    background: #fff;
    .checkout-header-row {
      a.checkout-header-logo {
        font-size: 30px;
        color: black;
        font-weight: 500;
      }
    }
  }
  .form-check.form-group.dna-share {
    display: flex;
}
  @media (max-width: 991px) {
    padding: 0px 0 50px;
  }
  @media (max-width: 767px) {
    padding: 0px 0px 50px;
  }
  && .order-kit {
    width: 100%;
    .order-wrapper {
      form.signupform.main-sign-frm {
        select {
          margin-bottom: 0;
        }
        .form-check {
          margin-top: 10px;
          input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }
          .form-check-label {
            position: relative;
            cursor: pointer;
            font-size: 14px;
            font-family: var(--common-font);
            font-weight: normal;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              -webkit-appearance: none;
              background-color: transparent;
              border: 2px solid var(--green);
              box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
                inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
              padding: 0px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              margin-right: 5px;
              border-radius: 30px;
              width: 18px;
              height: 18px;
            }
          }
          input:checked + label:before {
            background: var(--green);
          }
          input:checked + label:after {
            content: "";
            display: block;
            position: absolute;
            /* top: 10px; */
            left: 7px;
            width: 5px;
            height: 11px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        @media (max-width: 767px) {
          padding: 30px 20px;
          max-width: calc(100% - 20px);
        }
      }

      h5 {
        font-family: var(--inte-BD);
        color: var(--green);
        font-size: 26px;
        line-height: normal;
        text-align: center;
        margin: 0 0 30px;
        @media (max-width: 767px) {
          font-size: 22px;
          margin: 0 0 20px;
        }
      }
      .wrap-field {
        position: relative;
      }
      .sign-btn.mb-3 {
        text-align: center;
        margin-top: 30px;
        gap: 10px;
      }
      .kitform_action.d-flex {
        gap: 10px;
        margin-top: 25px;
        justify-content: center;
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
      }
      button {
        background: var(--green);
        border-radius: 30px;
        font-size: 16px;
        /* min-width: 150px; */
        color: #fff;
        font-weight: normal;

        &:disabled {
          background: grey;
        }
      }
      .buttons-change button {
        background: #fff;
        color: var(--green);
        border: 2px solid var(--green);
        min-width: 100px;
      }

      .mb-3.field-class {
        @media (max-width: 767px) {
          margin-bottom: 15px;
        }

        label {
          font-size: 16px;
        }
        .form-control,
        input[type="date"] {
          height: 40px;
          box-shadow: none;
          outline: none;
          border: 1px solid #ccc;
          border-radius: 30px;
          font-family: var(--common-font);
          display: flex;
          align-items: center;
        }
      }
      .canvas_section canvas.signatureCanvas {
        height: 120px;
        max-width: 400px;
        border: 2px dashed #ccc;
        border-radius: 8px;
        width: 100%;
        @media (max-width: 767px) {
          height: 10 0px;
        }
      }
      .buttons-change {
        margin-top: 10px;
      }
    }
  }
  .date-picker-sign {
    position: relative;
    label {
        position: absolute;
        left: 5px;
        top: 10px;
        padding: 0 5px 0 10px;
        background: #fff;
        margin: 0;
        z-index: 1;
    }
    .datepicker-icon {
        color: inherit;
        position: absolute;
        top: 14px;
        right: 15px;
        pointer-events: none;
        z-index: 1;
    }
    .react-datepicker__input-container {
        input.form-control {
            width: 100%;
            max-width: 100%;
            padding-left: 76px;
        }
    }
}



`;

const AutoShipProductStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  && .heading_section {
    margin: 50px 0 20px 0;
    text-align: center;
    p {
      @media (max-width: 767px) {
        line-height: normal;
      }
    }
    h2 {
      font-size: 24px;
      font-family: var(--inte-BD);
      margin-bottom: 0;
      line-height: normal;
      span {
        color: #f00;
        font-style: italic;
      }
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  .autoshipProduct_section {
    display: flex;
    justify-content: center;
    gap: 110px;
    text-align: center;
    @media (max-width: 767px) {
      max-width: 100%;
      gap: 20px;
      flex-direction: column;
    }
  }
  .container_autoship {
    max-width: 300px;
    /* border: 1px solid; */
    padding: 20px;
    background: #fff;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .name_and_price {
      p {
        font-size: 17px;
      }
    }
    .image {
      width: 300px;
      img {
        width: 100%;
      }
    }

    select {
      padding: 10px;
      border-radius: 30px;
      width: 100%;
      max-width: 200px;
      border: 1px solid rgb(221, 221, 221);
      text-align: center;
      background: transparent;
      font-size: 16px;
    }

    .amountSection {
      display: flex;
      justify-content: center;
      gap: 30px;
      div:first-child {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      .price {
        div:first-child {
          text-decoration: line-through;
        }
        div:last-child {
          color: rgb(255, 0, 0);
          font-family: var(--common-fontmd);
        }
      }
    }
    .main-qty-sections {
      padding: 0px;
      margin: 0px auto;
      width: fit-content;
      #qty {
        border-top: 1px solid var(--green);
        border-bottom: 1px solid var(--green);
        input {
          width: 40px;
          border: 0px;
          height: 37px;
          text-align: center;
          background: rgb(255, 255, 255);
          padding-right: 0px;
        }
        button {
          height: 40px;
          color: rgb(0, 0, 0);
          border-top: 0px;
          border-bottom: 0px;
          border-image: initial;
          padding: 0px;
          background: rgb(255, 255, 255);
          /* width: 40px; */
          min-width: 35px;
          border-right: 1px solid var(--green);
          border-left: 1px solid var(--green);
          border-radius: 0px;
          margin: 0px;
        }
      }
    }

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #ddd;
      border-radius: 20px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #2196f3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 9px;
      top: 3px;
      width: 8px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .submit_actions {
    margin-top: 5px;
    button {
      padding: 12px;
      width: 100%;
      max-width: 180px;

    }
  }  
`;

const KitCheckoutStyle = styled.div`
  && .main_sign_up_form_class {
    form {
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;

      .left_section {
        width: 55%;
        padding: 50px 50px 100px 15px;
        @media screen and (max-width:767px){
            width: 100%;
            padding: 15px;
        }
        @media screen and (max-width:575px){
          
        }

        .left_section-wrap {
          max-width: 750px;
          margin-left: auto;
          .field-class {
            .input_and_eye svg {
              fill: var(--green);
            }
            label.form-label {
              font-size: 20px;
              color: black;
              font-weight: 400;
              font-family: var(--common-font);
              margin: 0;
              line-height: 49px;
            }
          }

          .login_check_section {
            max-width: 100%;
            .main-left {
              padding: 0;
            }
          }

          .billing_shipping_section {
            flex-wrap: wrap;
            .main-sign-frm {
              .main-wrapper {
                padding: 0;
                .field-class {
                  &:first-child {
                    max-width: 100%;
                    flex: 0 0 100%;
                  }
                }
              }
            }
            .same_as_shipping {
              div {
                justify-content: flex-start !important;
              }
            }
          }
          .orderDetail_section {
            .cart_form {
              max-width: 100%;
              margin-bottom: 20px;
              .main-wrapper-check {
                padding: 0;
                .section_second {
                  .extra {
                    flex: 1 1 calc(33.3333% - 15px);
                    width: 100%;
                    max-width: calc(33.3333% - 15px);
                    padding: 0px;
                  }
                }
              }
            }
            .section_first {
              .checkout-payment-logo-wrap {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
              }
            }
          }
          .checkbox {
            background: gainsboro;
            background-color: rgb(242, 242, 242);
            border: 1px solid rgb(204, 204, 204);
            border-radius: 10px;
            margin: 0 0 20px;
            label.form-check-label {
              a.privacy_style {
                color: var(--green);
                font-weight: normal;
                font-family: var(--common-fontmd);
              }
            }
          }
          .order_button {
            button {
              width: 100%;
            }
          }

          .field-class {
            label.form-label {
              font-size: 24px;
            }
          }
          h2 {
            font-size: 24px;
            color: black;
            font-weight: 400;
            font-family: var(--common-font);
            margin: 0;
          }
        }
      }
      .right_section {
        width: 45%;
        padding: 50px 15px 100px 50px;
        background-color: rgb(242, 242, 242);
        @media screen and (max-width:767px){
            width: 100%;
            padding: 15px;
        }
      
        .right_section-wrap {
          max-width: 630px;
        }
        .table-responsive {
          max-width: 630px;
          border: 0;
          table {
            width: 100%;
          }
        }
        .productList {
          .productInfo {
            display: flex;
            align-items: center;
            width: 100%;
            .productName {
              flex: 1;
            }
          }
          .cupon_and_kiare_section {
            .apply-coupn {
              background: transparent;
              border: 0;
              width: 100%;
              max-width: 100%;
              padding: 0;
              .wrap-form {
                position: relative;
                display: flex;
                align-items: center;
                gap: 25px;
                @media screen and (max-width:575px){
                    flex-direction: column; 
                }
              }
              input.form-control {
                padding: 15px !important;
              }
              button.btn.btn-primary {
                position: static !important;
                margin: 0;
                height: 47px;
                max-width: max-content;
                padding: 0 25px;
                @media screen and (max-width:767px){
                    max-width:100%;
                }
              }
            }
          }
          .border_table_row {
            border-top: 1px solid #d1d1d1;
            padding-top: 35px;
            .Total {
              display: flex;
              justify-content: space-between;
              gap: 15px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    span.error,
    span.error_card {
      font-size: 14px;
      color: red;
    }
    .cupon_and_kiare_section {
      display: flex;

      .coupam-enter {
        width: 100%;
        h4.title {
          font-size: 20px;
          word-spacing: 10px;
          background: var(--site-purple);
          color: #fff;
          margin-top: 30px;
          padding: 10px;
          svg {
            float: right;
            margin-right: 20px;
            color: #f00;
          }
        }
      }
      .apply-coupn {
        margin-top: 40px;
        padding: 20px;
        float: left;
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
        .wrap-form {
          position: relative;
          .form-control {
            height: 47px;
            padding-right: 195px;
            border-radius: 30px;
          }
          button {
            position: absolute;
            right: 0px;
            top: -10px;
            max-width: 190px;
            height: 100%;
          }
        }
      }
    }

    select {
      background: #fff;
    }
    .billing_shipping_section {
      display: flex;
      gap: 30px;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      .main-sign-frm {
        background: #fff;
        .main-wrapper {
          flex-wrap: wrap;
          .field-class {
            margin: 0;
            max-width: calc(100% / 2 - 10px);
            flex: calc(100% / 2 - 10px);
            select {
              margin-bottom: 0;
            }
            &.company-line {
              max-width: 100% !important;
              flex: 100%;
            }
            input:not([type="checkbox"]) {
              height: 40px;
              box-shadow: none;
              outline: none;
              border: 1px solid #ccc;
              border-radius: 30px;
              font-family: var(--common-font);
              display: flex;
              -ms-flex-align: center;
              align-items: center;
            }
          }
          padding: 0 20px 20px;
          gap: 25px 20px;
          justify-content: center;
        }
        .same_as_shipping {
          div {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            font-size: 14px;
            color: var(--green);
            font-weight: 600;
            line-height: 1;
            justify-content: center;
            gap: 8px;
            input {
              margin: 0;
            }
          }
        }

        h4 {
          font-size: 25px;
          padding: 10px 0;
          background: var(--site-purple);
          color: #fff;
          margin: 0 0 25px;
          text-transform: capitalize;
        }
      }
      .autocomplete-dropdown-container {
        .suggestion-item.cursor-pointer {
          padding: 15px;
        }
        .suggestion-item.cursor-pointer {
          &:nth-child(2n + 1) {
            background: rgb(242, 242, 242);
          }
          span {
            font-size: 16px;
            color: rgb(0, 0, 0);
            display: block;
            line-height: 1.2;
          }
        }
      }
    }
    .orderDetail_section {
      .shipping > .row {
        margin: 0;
        border-top: 1px solid #ddd;
        padding: 10px;
        background: #fff;
      }
      .heading_section {
        margin: 20px 0;
        text-align: center;
        p {
          @media (max-width: 767px) {
            line-height: normal;
          }
        }
        h2 {
          font-size: 24px;
          font-family: var(--inte-BD);
          margin-bottom: 0;
          line-height: normal;
          span {
            color: #f00;
            font-style: italic;
          }
          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
      .main_shipping_method_class {
        .labelSection {
          display: flex;

          span {
            color: #f00;
          }
        }
        .radio_section_for_shipping_cost_gsn_related {
          p {
            margin-bottom: 0;
          }
        }
      }

      table {
        width: 100%;
        tr {
          &.border_table_row {
            border-top: 1px solid #eee;
            td {
              width: 50%;
            }
          }
          th {
            background: var(--site-purple);
            color: #fff;
          }
          th,
          td {
            padding: 10px 0;
            text-align: center;
            font-family: var(--common-fontmd);
          }
          td {
            background: #fff;
            .Total {
              display: flex;
              justify-content: right;
              width: 100%;
              padding-right: 30px;
              strong {
                padding-right: 10px;
                font-size: 16px;
              }
            }
            &.radio_section_div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              .realted_radio {
                top: -23px;
              }
            }
            .main-qty-sections {
              padding: 0;
              margin: 0 auto;
              width: fit-content;

              #qty {
                position: relative;
                align-items: center;
                border: 1px solid var(--green);
                border-left: 0;
                border-right: 0;
                width: max-content;

                button {
                  height: 40px;
                  color: #000;
                  border: 0;
                  padding: 0;
                  background: #fff;
                  min-width: 30px;
                  border-right: 1px solid var(--green);
                  border-left: 1px solid var(--green);
                  border-radius: 0;
                  margin: 0;
                }
                input {
                  width: 40px;
                  border: 0;
                  height: 37px;
                  text-align: center;
                  background: #fff;
                  padding-right: 0;
                }
              }
            }
            &.cart-product-details {
              display: table-cell;
              .box {
                margin: 0 auto;
                select {
                  width: 155px;
                  height: 40px;
                  border: 1px solid #ddd;
                  border-radius: 50px;
                  background: transparent;
                  padding: 0 10px;
                }
              }
            }

            &.amount_strick div {
              &:first-child {
                text-decoration: line-through;
              }
              &:last-child {
                color: #f00;
                font-family: var(--common-fontmd);
              }
            }
          }
        }
        @media (max-width: 767px) {
          white-space: nowrap;
        }
      }
      .cart_form {
        max-width: 600px;
        background: #fff;
        margin: 50px 0;
        width: 100%;
        @media (max-width: 767px) {
          max-width: 100%;
          margin-top: 30px;
        }
        input {
          border-radius: 30px;
          box-shadow: none;
          border: 1px solid #ccc;
          height: 40px;
          width: 100%;
        }
        h4 {
          font-size: 24px;
          padding: 10px 0;
          background: var(--site-purple);
          color: #fff;
        }
        .main-wrapper-check {
          padding: 20px;
          .section_first {
            .form-wrappper {
              margin-bottom: 15px;

              .error_card {
                font-size: 14px;
                color: #f00;
              }
            }
          }
          .section_second {
            display: flex;
            gap: 15px;

            .col-md-6.extra {
              padding: 0;

              select {
                border-radius: 30px;
                box-shadow: none;
                border: 1px solid #ccc;
                height: 40px;
              }
            }
          }
          .save-credit-check {
            display: flex;
            gap: 5px;
            margin-top: 10px;
            align-items: center;
            input {
              margin: 0;
              max-width: fit-content;
            }
          }
        }
      }
    }

    .login_check_section {
      max-width: 690px;
      width: 100%;
      background: #fff;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        max-width: 100%;
      }
      h4 {
        margin-top: 0;
        font-size: 25px;
        background: var(--site-purple);
        color: #fff;
        padding: 10px;
      }
      .main-left {
        padding: 10px;

        input {
          height: 40px;
          border-radius: 30px;
          padding: 10px;
        }

        .input_and_eye {
          position: relative;

          svg {
            float: right;
            position: absolute;
            top: 13px;
            right: 14px;
          }
        }
      }
    }
    .forLogin_card_section {
      display: flex;
      flex-direction: column;
      gap: 15px;
      border: 1px solid #eee;
      margin-top: 15px;
      padding: 15px;
      background: #fff;

      label {
        color: #000;
        font-size: 16px;
        span {
          color: #f00;
        }
      }
      .saved-main-card.d-flex {
        border: 2px solid;
        align-items: center;
        border-radius: 7px;
        width: 100%;
        height: 63px;
        max-width: 350px;
        background: #f3f3f3;
        margin-top: 19px;
        gap: 15px;
        padding: 10px;
        margin: 0;
        font-size: 18px;
        word-spacing: 1px;
      }
      .saved_card_login {
        display: flex;
        gap: 62px;

        /* input[type="radio"] {
                                                            margin-bottom: 15px;
                                                        } */
      }
      button {
        width: 100%;
        max-width: fit-content;
      }
    }
    .checkbox {
      position: relative;
      padding: 20px 30px;
      background: rgb(255, 255, 255);
      margin-top: 40px;
      @media (max-width: 767px) {
        margin-top: 20px;
        padding: 20px;
      }
      .form-check {
        margin-bottom: 15px;

        input {
          padding: 0px;
          height: initial;
          width: initial;
          margin-bottom: 0px;
          display: none;
          cursor: pointer;

          &:checked + label {
            color: var(--green);
            &::before {
              background: var(--green);
            }
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 4px;
              left: 6px;
              width: 5px;
              height: 11px;
              border-style: solid;
              border-color: rgb(255, 255, 255);
              border-image: initial;
              border-width: 0px 2px 2px 0px;
              transform: rotate(45deg);
            }
          }
        }

        label {
          position: relative;
          cursor: pointer;
          padding-left: 0px;
          &::before {
            content: "";
            appearance: none;
            background-color: transparent;
            border: 2px solid var(--green);
            box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px,
              rgb(0 0 0 / 5%) 0px -15px 10px -12px inset;
            padding: 0px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            border-radius: 30px;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .main_sign_up_form_class {
      padding: 0;
      form{
        @media screen and (max-width:767px){
            flex-direction: column;
        }
      }
    }
    .section_second {
      flex-wrap: wrap;
      .extra {
        width: 100%;
      }
    }
  }
  .data-city-zip {
    width: 100%;
    display: flex;
    gap: 14px;
    flex-wrap:wrap;

    @media only screen and (max-width:767px) {
      flex-wrap: wrap;
      
    }
    @media only screen and (max-width:575px) {
      display:grid;
      width:100%;
    }
   .field-class {
    flex: 0 0 33.33%;
    max-width: calc(100% / 3 - 10PX) !important;
    @media only screen and (max-width:575px) {
      max-width: 100%!important;
      flex: 0 0 100%;
    }
}
}
input.form-control{
    height: 40px;
    border-radius: 30px;
    padding: 10px
}

`;

const ChoodeConsentDialogStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .first_button,
  .second_button {
    text-align: center;
    button {
      width: 100%;
      max-width: 350px;
      padding: 10px;
      border-radius: 30px;
      font-size: 18px;
      text-transform: capitalize;
      background-image: linear-gradient(90deg, #fbd709 0%, #e94f24 100%);
      font-family: var(--common-font);
      transition: 0.3s ease all;

      &:hover {
        color: #fff;
      }
      @media (max-width: 480px) {
        font-size: 16px;
      }
      @media (max-width: 380px) {
        font-size: 14px;
      }
    }
  }
  `;

export default KitPageValues;
export { KitCheckoutStyle, ChoodeConsentDialogStyle, AutoShipProductStyle };
