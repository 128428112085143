import React from 'react'

const CodeComplexSection = ({ product }) => {
    return (
        <>
            <div className='home_fourth_section seen-on'>
                <div className='fourth_first_container'>
                    <div className='tool-heading'>
                        <h2 className='color'>TOOL #2</h2>
                    </div>
                    <h2>CODE Complex</h2>
                    <h3>Nearly 400 million combinations possible.</h3>
                    <h3><p><span>ONE</span> FORMULATION FOR  <span className='color-gradient'>YOU.</span></p> </h3>
                </div>
                <div className='fourth_second_container'>
                    <div className='capsule'>
                        {product?.product_images?.length > 0 && <img src={process.env.API_URL + product?.product_images?.[0]?.image} />}
                        <div>
                            <p>CODE Complex is a revolutionary DNA-customized<br />nutritional supplement.</p>
                            <p className='boldOne'><b>Personalized biohacking simplified.</b></p>
                            <p>
                                Containing key vitamins, minerals and micronutrients,<br />
                                your CODE Complex formula gives your body<br />
                                what your body needs.
                            </p>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default CodeComplexSection